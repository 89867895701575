import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Collin from './Collin.jpg';
import Burgerking from './Burgerking.jpg';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`We are now over 4 weeks into the lockdown and the curve appears to be flattening. They're talking about opening up parks and beaches again. I head into my 2pm Saturday shift at hillcrest expecting the ER to be empty as it has been for the past couple weeks. Instead I walk in to a resident intubating a patient in bed 6. 4 gurneys line the ambulance bay awaiting to be triaged into rooms. Its noisy. Today feels like a real ER day. I look at one of the gurneys and immediately recoganize one of the patients. Ah our drunken frequent flyer CB is back! Bro I thought you had died! I never thought I'd say this, but I actually missed your drunk belligerent ass. Welcome back my friend. Lets get you into room 16A and go through our little dance like we always we always do.`}</p>
    <p>{`Meanwhile Billy, our case manager comes to me and goes, "you know he's a celebrity right?" and hands me this: `}</p>
    <img src={Collin} style={{
      width: '100%'
    }} />
    <p>{`So apparently, 9 years ago, our boy CB highjacked an airport shuttle using a gun (which turned out to be a cigarette lighter) and forced the driver to take him to Burger King where he was later found by police enjoying what I assume to be a whopper. WHAT A LEGEND! `}</p>
    <p>{`You do it your way Collin!`}</p>
    <img src={Burgerking} style={{
      width: '100%'
    }} />
    <p><em parentName="p">{`Some names and identifying details have been changed to protect the privacy of individuals. Any resemblance to actual persons, living or dead, or actual events is purely coincidental.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      