import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Cockring_CC from './Cockring_CC.jpeg';
import Cock from './Cock.jpeg';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`NSFW!!!!`}</h2>
    <h2>{`DO NOT SCROLL TO THE BOTTOM OF THIS PAGE IF YOU DO NOT WANT TO SEE A PICTURE OF A VERY SWOLLEN PENIS`}</h2>
    <h3>{`Chief Complaint:`}</h3>
    <img src={Cockring_CC} style={{
      width: '100%'
    }} />
    <p>{`So this guy is brought in by EMS for a "swollen penis". Apparently he was arrested by police for another unclear reason. When they were booking him for jail, they noticed him hunching over and grabbing his crotch. Worried that he may be hiding drug paraphernalia in his underwear, they decided to do a strip search, only to find this: `}</p>
    <img src={Cock} style={{
      width: '100%'
    }} />
    <p>{`It looks like he put his penis through what looks like a ?gear? `}</p>
    <p>{`Me: "Sir, we need to take this off immediately"`}</p>
    <p>{`Patient: "I don't want it off"`}</p>
    <p>{`Me: " If we do not take this ring off, your penis will die and fall of by itself "`}</p>
    <p>{`Patient: "Thats some feminist shit right there"`}</p>
    <p>{`Well... ok buddy. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      